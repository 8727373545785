import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/app.states';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationRequestAction } from './store/common/common.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Member Join';

  constructor(public store: Store<AppState>,
    public translate: TranslateService,
    private router: Router,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');

    this.store.dispatch(AppConfigurationRequestAction());

  }

}
