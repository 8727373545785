<div id="member-view-container">

  <form [ngrxFormState]="form" *ngIf="form$ | async; let form">
    <a class="btn btn-link clear" *ngIf="hidesearch" (click)="onClearClick()">Clear</a>
    <mat-progress-bar class="search-loading" mode="indeterminate" *ngIf="isSearchLoading$ | async"></mat-progress-bar>
    <app-entry title="Search Superannuation Provider" [control]="form.controls.q" icon="search" [customList]="list$"
      *ngIf="!hidesearch">
    </app-entry>

    <mat-error class="provider-not-selected" *ngIf="!hidesearch && !form.value.q">
      Please select the provider
    </mat-error>

    <div *ngIf="form.value.selected.name && !form.value.q">
      <app-readonly title="Name of the Fund" [value]="form.value.selected.name"></app-readonly>
      <!-- <app-readonly title="Product" [value]="form.value.selected.productName"></app-readonly> -->
      <app-readonly title="ABN of the Fund" [value]="form.value.selected.abn"></app-readonly>
      <app-readonly title="Unique Superannuation Identifier (USI)" [value]="form.value.selected.usi">
      </app-readonly>
    </div>
    <div *ngIf="form.value.q" class="show-results">
      <label class="float-left">Select the provider</label>
      <div class="float-right">
        <mat-icon (click)="onCloseClick()">close</mat-icon>
      </div>
      <div class="alert alert-info mt-4" *ngIf="(list$ | async).length == 0 && !(isSearchLoading$ | async)">No provider
        found</div>
      <ul *ngIf="form.value.q && (list$ | async).length >0">
        <li *ngFor="let item of list$ | async" (click)="onSelectOption(item)" class="provider-item">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">
              <b class="provider-value w-100">{{item.name}}</b>
            </div>
            <div class="d-flex flex-row mt-2">
              <div class="provider-title" style="width: 80px;">Product: </div>
              <div class="provider-value ">{{item.productName}}</div>
            </div>
            <div class="d-flex flex-row ">
              <div class="provider-title" style="width: 80px;">ABN: </div>
              <div class="provider-value ">{{item.abn}}</div>
            </div>
            <div class="d-flex flex-row">
              <div class="provider-title " style="width: 80px;">USI:</div>
              <div class="provider-value ">{{item.usi}}</div>
            </div>

          </div>
          <div class="select-item">
            <mat-icon>arrow_right</mat-icon>
          </div>
        </li>
      </ul>
    </div>

  </form>
</div>
