import { createSelector } from '@ngrx/store';
import { AppState } from '../app.states';
import { ICommonState } from './common.state';

export const getCommon = (state: AppState) => state.commonState;

export const commomState_IsLoading = createSelector(
  getCommon,
  (state: ICommonState) => state.isLoading
);

export const commomState_AppConfig = createSelector(
  getCommon,
  (state: ICommonState) => state.appConfig
);

export const commomState_AppConfig_Gender = createSelector(
  getCommon,
  (state: ICommonState) => state.appConfig.genderList??[]
);

export const commomState_AppConfig_FundUsiList = createSelector(
  getCommon,
  (state: ICommonState) => state.appConfig.fundUsiList??[]
);

