import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { SuperannuationProviderItemModel } from './state';
import { ProviderSearchModel } from 'src/app/model/provider-search.model';

export const ResetAction = createAction('[Superannuation provider search] reset');
export const ClearSearchAction = createAction('[Superannuation provider search] clear search');
export const RequestAction = createAction('[Superannuation provider search] request data search',props<{ data: ProviderSearchModel }>());
export const SuperAnnuationIsLoadingAction = createAction('[Superannuation provider search] is loading',props<{ payload: boolean }>());

export const ResponseAction = createAction('[Superannuation provider search] response data search',
  props<{ payload: SuperannuationProviderItemModel[] }>());

export const SelectOptionAction = createAction('[Superannuation provider search] select option',props<{ payload: SuperannuationProviderItemModel }>());
export const ClearSelectedSearchAction = createAction('[Superannuation provider search] clear selected item search');
