import * as Actions from './common.actions';
import { ICommonState } from './common.state';
import { Action, createReducer, on } from '@ngrx/store';
import { AppConfigModel } from 'src/app/model/app-config.model';

export const commonState: ICommonState = {
  isLoading: false,
  appConfig: new AppConfigModel()
};

const reducerList = createReducer(commonState,

  on(Actions.IsLoadingAction, (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    };
  }),

  on(Actions.AppConfigurationResponseAction, (state, { payload }) => {
    return {
      ...state,
      appConfig: payload,
    };
  }),
);

export function commonReducer(state: any | undefined, action: Action) {
  return reducerList(state, action);
}
