import { SuperannuationProviderSearchEffects } from './components/superannuation-provider-search/effects';
import { LeadService } from './services/lead.service';
import { MemberJoinEffects } from './views/member-join/effects';
import { MemberJoinComponent } from './views/member-join/member-join.component';
import { SuperannuationProviderSearchComponent } from './components/superannuation-provider-search/superannuation-provider-search.component';
import { ConfirmationDialog } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedComponentsModule } from './components/component.module';
import { PermissionGuardService } from './route-guard/permission-guard.service';
import { MessagePipe } from './pipes/message';
import { CommonEffects } from './store/common/common.effects';
import { HelperService } from './services/helper.service';
import { MainEffects } from './components/layout/main/effects';
import { HttpCustomInterceptor } from './services/http-interceptor';
import { MainMasterComponent } from './components/layout/main/main.master';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { reducers } from './store/app.reducers';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { NotLoggedInMasterComponent } from './components/layout/not-loggedin/not-loggedin.master'
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './app.material.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuardService } from './route-guard/auth-guard.service';
import { TimeagoModule } from 'ngx-timeago';
import { Helper } from './helper/helper-functions';
import { clearState } from './store/meta-reducer';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { UserServiceConfig } from '@ifaa-components/ui-components/lib/services/userServiceConfig';

export const metaReducers: MetaReducer<any>[] = [clearState];
Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagEnv,
  enabledReleaseStages: ['Production', 'Staging', 'Uat', 'unknown'],
  onError: function (event) {
    event.addMetadata('tenant', {
      tenant: environment.code
    })
  }
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    MemberJoinComponent,
    AppComponent,
    NotLoggedInMasterComponent,
    SuperannuationProviderSearchComponent,
    MainMasterComponent,
    ConfirmationDialog,
    MessagePipe,
  ],
  entryComponents: [
    ConfirmationDialog
  ],
  imports: [
    NgrxFormsModule,
    BrowserModule,
    IfaaUiComponentsModule.forRoot({ entryAppearance: 'outline', requiredIconInvalid:'report' } as UserServiceConfig),
    CommonModule,
    FormsModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    // AppComponentsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    MaterialModule,
    HttpClientModule,
    TimeagoModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 20, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    // RouterModule.forRoot(appRoute),
    ReactiveFormsModule,
    EffectsModule.forRoot([
      MemberJoinEffects,
      MainEffects,
      CommonEffects,
      SuperannuationProviderSearchEffects
    ])
  ],
  providers: [
    Helper,
    AuthGuardService,
    LeadService,
    HelperService,
    MessagePipe,
    PermissionGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
