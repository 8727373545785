// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/app.states';
// import { commomState_SystemConfig_Messages } from '../store/common/common.selectors';
import { Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'message'
})
export class MessagePipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  // messages$ = this.store.pipe(select(commomState_SystemConfig_Messages));

  async transform(value: string): Promise<string> {
    // const messages = await this.getValue(this.messages$)

    // return messages[value];
    return '';
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

}
