import { LeadService } from './../../services/lead.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ProviderSearchRequestAction, ProviderSearchResponseAction } from './actions';
import { EMPTY, of } from 'rxjs';
import { IsLoadingAction } from '../../store/common/common.actions';

@Injectable()
export class MemberJoinEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: LeadService,
    private router: Router) {
  }

  validateEmail$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ValidateEmailRequestAction),
    switchMap((action: any) => this.service.validateEmail(action.payload).pipe(
      map((data: any) => {
        window.localStorage.setItem('token_name', data.data);
        this.service.showOk(data.message);
        return ActionsList.ValidateEmailResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  validateMobile$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ValidateMobileRequestAction),
    switchMap((action: any) => this.service.validateMobile(action.payload).pipe(
      map((data: any) => {
        window.localStorage.setItem('token_name', data.data);
        this.service.showOk(data.message);
        return ActionsList.ValidateMobileResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  resendEmail$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.ResendEmailRequestAction),
    switchMap((action: any) => this.service.resendEmail(action.payload).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        return ActionsList.ResendEmailResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  update$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.UpdateFieldRequestAction),
    switchMap((action: any) => this.service.update(action.step, action.field, action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        return ActionsList.UpdateFieldResponseAction({ data: data.data });
      }),
      catchError(err => {
        return of(ActionsList.ErrorUpdatingAction());
      })
    )),
  ));

  updateNextPage$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.MoveNextPageRequestAction),
    switchMap((action: any) => this.service.update(action.step, action.field, action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0);
        return ActionsList.MoveNextPageResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  checkEmailCode$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CheckEmailCodeRequestAction),
    switchMap((action: any) => this.service.checkEmailCode(action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        window.localStorage.setItem('token_name', data.data);
        return ActionsList.CheckEmailCodeResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  checkMobileCode$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CheckMobileCodeRequestAction),
    switchMap((action: any) => this.service.checkMobileCode(action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0);
        return ActionsList.CheckMobileCodeResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  sendMobileCode$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.SendMobileCodeRequestAction),
    switchMap((action: any) => this.service.sendMobileCode(action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.data.message);
        return ActionsList.SendMobileCodeResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  providerSearch$ = createEffect(() => this.actions$.pipe(
    ofType(ProviderSearchRequestAction),
    switchMap((action: any) => this.service.providerSearch(action.q).pipe(
      map((data: any) => {
        return ProviderSearchResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  complete$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CompleteRequestAction),
    switchMap((action: any) => this.service.complete(action.data).pipe(
      map((data: any) => {
        this.service.showOk(data.message);
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0)
        return ActionsList.CompleteResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  createLead$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CreateLeadRequestAction),
    switchMap((action: any) => this.service.createLead(action.data).pipe(
      map((data: any) => {
        window.localStorage.setItem('token_name', data.data);
        this.service.showOk(data.message);
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0)
        return ActionsList.CreateLeadResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
