import { Subscription } from 'rxjs';

import { ActivatedRoute } from "@angular/router";

export class ComponentBase {

  public set sub(value: Subscription) {
    this.addSubscription(value);
  }
  private subscriptions: Subscription[] = [];

  constructor() {
  }


  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ngOnInitBase() {
  }

  getRouteParam(route: ActivatedRoute, name: string, callback, callbackNotFound = null) {
    const sub = route
      .params
      .subscribe(params => {
        const val = params[name];
        if (val) {
          callback(val);
        }
        else {
          if (callbackNotFound)
            callbackNotFound()
        }
      });
    this.addSubscription(sub);
  }

  ngOnDestroyBase() {
    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    });
  }

  getUrlParameterByName(name: string, url?: any) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
