import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class Helper {
  constructor() {

  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

  getFinancialYear(date: Date) {
    if (date >= new Date(date.getFullYear(), 6, 1)) {
      return {
        from: new Date(date.getFullYear(), 6, 1),
        to: new Date(date.getFullYear() + 1, 5, 30)
      }
    }
    else {
      return {
        from: new Date(date.getFullYear() - 1, 6, 1),
        to: new Date(date.getFullYear(), 5, 30)
      }
    }
  }
}

