import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appanimatecss]',
})
export class AnimateCssDirective {
  constructor(public renderer: Renderer2, public hostElement: ElementRef) {
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {
    this.renderer.addClass(this.hostElement.nativeElement, 'animate__animated');
    this.renderer.addClass(this.hostElement.nativeElement, 'animate__faster');

  }

  @Input() set appanimatecss(value: string) {
    if (value) {
      this.renderer.addClass(this.hostElement.nativeElement, 'animate__' + value);
    }
    else {
      this.renderer.addClass(this.hostElement.nativeElement, 'animate__fadeInUpSmall');
    }
  }

}
