import { AppState } from 'src/app/store/app.states';
import { createSelector } from '@ngrx/store';

import { ISuperannuationProviderSearchState } from './state';

const memberPortalState = (state: AppState) => state.superannuationProviderSearchState;

export const superannuationProviderSearch_Form = createSelector(
  memberPortalState,
  (state: ISuperannuationProviderSearchState) => state.form
);

export const superannuationProviderSearch_LastModified = createSelector(
  memberPortalState,
  (state: ISuperannuationProviderSearchState) => state.lastModifiedTime
);

export const superannuationProviderSearch_List = createSelector(
  memberPortalState,
  (state: ISuperannuationProviderSearchState) => state.form.value.list);

  export const superannuationProviderSearch_isSearchLoading = createSelector(
  memberPortalState,
  (state: ISuperannuationProviderSearchState) => state.isSearchLoading);
