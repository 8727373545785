import { AppListDirective } from './list.directive';
import { MessageDirective } from './message.directive';
import { FeatureToggleDirective } from './feature-toggle.directive';
import { AnimateCssDirective } from './animatecss.directive';
import { AppButtonDirective } from './appbutton.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AnimateCssDirective,
    FeatureToggleDirective,
    MessageDirective,
    AppListDirective,
    AppButtonDirective
  ],
  exports: [
    AnimateCssDirective,
    FeatureToggleDirective,
    MessageDirective,
    AppListDirective,
    AppButtonDirective
  ]
})
export class SharedDirectivesModule { }
