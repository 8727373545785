import { HelperService } from './../../services/helper.service';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { AppConfigurationRequestAction, AppConfigurationResponseAction } from './common.actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class CommonEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private service: HelperService) {
  }

  getSystemConfig$ = createEffect(() => this.actions$.pipe(
    ofType(AppConfigurationRequestAction),
    switchMap((action: any) => this.service.appConfiguration().pipe(
      map((data: any) => {
        return AppConfigurationResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
