import { FormGroupState } from 'ngrx-forms';

export interface ISuperannuationProviderSearchState {
  form: FormGroupState<SuperannuationProviderSearchModel>;
  lastModifiedTime: Date;
  isSearchLoading: boolean;
}


export class SuperannuationProviderSearchModel {
  list: SuperannuationProviderItemModel[] = [];
  selected: SuperannuationProviderItemModel = new SuperannuationProviderItemModel();
  q: string = '';
}

export class SuperannuationProviderItemModel {
  superannuationProviderId: number = 0;
  name: string = '';
  productName: string = '';
  abn: string = '';
  usi: string = '';
}
