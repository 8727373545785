import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotLoggedInMasterComponent } from './components/layout/not-loggedin/not-loggedin.master'
import { MemberJoinComponent } from './views/member-join/member-join.component';


var routes: Routes = [
  {
    path: '',
    component: NotLoggedInMasterComponent,
    children: [
      {
        outlet: 'master',
        path: '',
        component: MemberJoinComponent
      }
    ],
    pathMatch: 'full'
  },

  // {
  //   path: '',
  //   redirectTo: '/member-join',
  //   pathMatch: 'full'
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
