import { AppConfigModel } from './../../model/app-config.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const IsLoadingAction = createAction('[Is app loading] loading',
  props<{ payload: boolean }>());

export const AppConfigurationRequestAction = createAction('[System Config] get data');

export const AppConfigurationResponseAction = createAction('[System Config] set data',
  props<{ payload: AppConfigModel }>());

export const IsLoggedInRequestAction = createAction('[User check] is logged in request');

export const LogoutRequestAction = createAction('[Logout request]');



