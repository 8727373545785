import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgrxFormsModule } from 'ngrx-forms';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../app.material.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgrxFormsModule,
    FormsModule,
    SharedDirectivesModule
  ],
  declarations: [
    FooterComponent,
  ],
  exports: [
    FooterComponent,
  ]
})
export class SharedComponentsModule { }
