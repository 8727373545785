import { CheckEmailCodeModel, CheckMobileCodeModel, SendMobileCodeModel } from './../model/check-email-code.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ValidateEmailModel, ValidateMobileModel } from '../model/validate-email.model';
import { MemberJoinModel } from '../model/member-join.model';
import {ProviderSearchModel} from "../model/provider-search.model";

@Injectable()
export class LeadService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  validateEmail(obj: ValidateEmailModel): Observable<any> {
    return this.http.post<ValidateEmailModel>(environment.apiUrl + `/Lead/validate-email`, obj);
  }

  validateMobile(obj: ValidateMobileModel): Observable<any> {
    return this.http.post<ValidateMobileModel>(environment.apiUrl + `/Lead/validate-mobile`, obj);
  }

  resendEmail(obj: ValidateEmailModel): Observable<any> {
    return this.http.post<ValidateEmailModel>(environment.apiUrl + `/Lead/resend-email`, obj);
  }

  checkEmailCode(obj: CheckEmailCodeModel): Observable<any> {
    return this.http.post<CheckEmailCodeModel>(environment.apiUrl + `/Lead/check-email-code`, obj, { headers: this.headers() });
  }

  checkMobileCode(obj: CheckMobileCodeModel): Observable<any> {
    return this.http.post<CheckMobileCodeModel>(environment.apiUrl + `/Lead/check-mobile-code`, obj, { headers: this.headers() });
  }

  sendMobileCode(obj: SendMobileCodeModel): Observable<any> {
    return this.http.post<SendMobileCodeModel>(environment.apiUrl + `/Lead/send-mobile-code`, obj, { headers: this.headers() });
  }

  update(step: number, field: string, obj: MemberJoinModel): Observable<any> {
    return this.http.put<MemberJoinModel>(environment.apiUrl + `/Lead/step/${step}/field/${field}/update`, obj, { headers: this.headers() });
  }

  complete(obj: MemberJoinModel): Observable<any> {
    return this.http.post<MemberJoinModel>(environment.apiUrl + `/Lead/complete`, obj, { headers: this.headers() });
  }

  createLead(obj: MemberJoinModel): Observable<any> {
    return this.http.post<MemberJoinModel>(environment.apiUrl + `/Lead/create-lead`, obj);
  }

  providerSearch (obj : ProviderSearchModel): Observable<any> {
    return this.http.post<ProviderSearchModel>(environment.apiUrl + `/Lead/provider-search`,obj, { headers: this.headers() });
  }

}
