import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.states';
import { commonReducer } from './common/common.reducer'
import { memberJoinReducer } from '../views/member-join/reducer'
import { superannuationProviderSearchReducer } from '../components/superannuation-provider-search/reducer'

export const reducers: ActionReducerMap<AppState> = {
  memberJoinState: memberJoinReducer,
  commonState: commonReducer,
  superannuationProviderSearchState: superannuationProviderSearchReducer,

};
