import { SuperannuationProviderItemModel } from './../../model/superannuation-provider.model';
import { OkModel } from './../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ValidatedEmailModel, ValidatedMobileModel, ValidateEmailModel, ValidateMobileModel } from 'src/app/model/validate-email.model';
import { MemberJoinModel, SendMobileCodeResponseModel } from 'src/app/model/member-join.model';
import { CheckEmailCodeModel, CheckMobileCodeModel, SendMobileCodeModel } from 'src/app/model/check-email-code.model';
import { ErrorPageModel } from './state';

export const ValidateEmailRequestAction = createAction('[Validate email] validate',
  props<{ payload: ValidateEmailModel }>());
export const ValidateEmailResponseAction = createAction('[Validate email] set validate', props<{ payload: ValidatedEmailModel }>());

export const ValidateMobileRequestAction = createAction('[Validate mobile] validate',
  props<{ payload: ValidateMobileModel }>());
export const ValidateMobileResponseAction = createAction('[Validate mobile] set validate', props<{ payload: ValidatedMobileModel }>());

export const ResendEmailRequestAction = createAction('[Validate email] resend',
  props<{ payload: ValidateEmailModel }>());
export const ResendEmailResponseAction = createAction('[Validate email] set resend', props<{ payload: ValidatedEmailModel }>());

export const GoToStepAction = createAction('[member join] go to step', props<{ index: number }>());
export const NextStepAction = createAction('[member Join] move next step');
export const PreviousStepAction = createAction('[member Join] move previous step');

export const ValidateFormAction = createAction('[Member join] validate form');

export const UpdateFieldRequestAction = createAction('[member join] update request',
  props<{ step: number, field: string, data: MemberJoinModel }>());
export const UpdateFieldResponseAction = createAction('[member join] update response',
  props<{ data: OkModel<string> }>());

export const MoveNextPageRequestAction = createAction('[member join] update next page request',
  props<{ step: number, field: string, data: MemberJoinModel }>());
export const MoveNextPageResponseAction = createAction('[member join] update next page response',
  props<{ data: OkModel<string> }>());

export const AddSuperFundAction = createAction('[member join] add super fund');
export const RemoveSuperFundAction = createAction('[member join] remove super fund', props<{ index: number }>());

export const SelectInvestmentOptionAction = createAction('[member join] select investent option', props<{ option: number }>());

export const CheckEmailCodeRequestAction = createAction('[member join] CheckEmailCode  request',
  props<{ data: CheckEmailCodeModel }>());
export const CheckEmailCodeResponseAction = createAction('[member join] CheckEmailCode  response',
  props<{ data: OkModel<boolean> }>());

export const CheckMobileCodeRequestAction = createAction('[member join] CheckMobileCode  request',
  props<{ data: CheckMobileCodeModel }>());
export const CheckMobileCodeResponseAction = createAction('[member join] CheckMobileCode  response',
  props<{ data: OkModel<boolean> }>());

export const SendMobileCodeRequestAction = createAction('[member join] sendMobileCode  request',
  props<{ data: SendMobileCodeModel }>());
export const SendMobileCodeResponseAction = createAction('[member join] sendMobileCode  response',
  props<{ data: SendMobileCodeResponseModel }>());

export const ProviderSearchRequestAction = createAction('[Superannuation provider search] request data', props<{ q: string }>());

export const ProviderSearchResponseAction = createAction('[Superannuation provider search] response data ',
  props<{ payload: SuperannuationProviderItemModel[] }>());


export const SelectedProviderAction = createAction('[Superannuation provider search] selected provider',
  props<{ index: number, id: number, usi: string, name: string }>());


export const CompleteRequestAction = createAction('[member join] Complete request',
  props<{ data: MemberJoinModel }>());
export const CompleteResponseAction = createAction('[member join] Complete response',
  props<{ data: OkModel<boolean> }>());


export const CreateLeadRequestAction = createAction('[member join] create lead request',
  props<{ data: MemberJoinModel }>());
export const CreateLeadResponseAction = createAction('[member join] create lead response',
  props<{ data: OkModel<boolean> }>());

export const SetErrorPageDataAction = createAction('[member join] error page data',
  props<{ data: ErrorPageModel }>());

export const SkipRollinAction = createAction('[member join] skip rollin');
export const SkipTFNAction = createAction('[member join] skip tfn');

export const ErrorUpdatingAction = createAction('[member join] set error updating')