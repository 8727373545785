import { ComponentBase } from '../../views/component-base';
import { AppState } from '../../store/app.states';
import { superannuationProviderSearch_Form, superannuationProviderSearch_List, superannuationProviderSearch_isSearchLoading } from './selectors';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ClearSearchAction, RequestAction, ResetAction, SelectOptionAction } from './actions';
import { SuperannuationProviderItemModel } from './state';
import { IsLoadingAction } from 'src/app/store/common/common.actions';
import { debounceTime } from 'rxjs/operators';
import { commomState_AppConfig_FundUsiList, commomState_AppConfig_Gender } from 'src/app/store/common/common.selectors';
import { ProviderSearchModel } from 'src/app/model/provider-search.model';

@Component({
  selector: 'app-superannuation-provider-search',
  templateUrl: './superannuation-provider-search.component.html',
  styleUrls: ['./superannuation-provider-search.component.scss']
})
export class SuperannuationProviderSearchComponent extends ComponentBase implements OnInit, OnDestroy {

  @Output() onSelected = new EventEmitter<any>();

  form$ = this.store.pipe(select(superannuationProviderSearch_Form));
  list$ = this.store.pipe(select(superannuationProviderSearch_List));
  isSearchLoading$ = this.store.pipe(select(superannuationProviderSearch_isSearchLoading));
  fundUsiList$ = this.store.pipe(select(commomState_AppConfig_FundUsiList));
  @Input() hidesearch: boolean = false;

  q: string = "";
  fundUsiList :string[] = [];

  constructor(public store: Store<AppState>) {
    super();
  }


  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.list$.subscribe(x => {
      this.store.dispatch(IsLoadingAction({ payload: false }));
    });

    this.sub = this.fundUsiList$.subscribe(x => {
      this.fundUsiList = x;
    });

    this.sub = this.form$
    .pipe(
      debounceTime(300))
      .subscribe(x => {
      if (this.q != x.value.q && x.value.q) {
        this.q = x.value.q;
        var request = new ProviderSearchModel();
        request.query = this.q;
        request.fundUsiList = this.fundUsiList;
        this.store.dispatch(RequestAction({data :request}));
      }

      if (x.value.selected && x.value.selected.superannuationProviderId) {
        this.hidesearch = true;
        this.onSelected.emit({ id: x.value.selected.superannuationProviderId, usi: x.value.selected.usi, name: x.value.selected.name });
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onClearClick() {
    this.q = '';
    this.hidesearch = false;
    this.store.dispatch(ResetAction());
    this.onSelected.emit({ id: 0, usi: '', name: '' });
  }

  onSelectOption(item: SuperannuationProviderItemModel) {
    this.hidesearch = true;
    this.store.dispatch(SelectOptionAction({ payload: item }));
    this.onSelected.emit({ id: item.superannuationProviderId, usi: item.usi, name: item.name });
  }

  onCloseClick() {
    this.store.dispatch(ClearSearchAction());
  }

}
