import { AddressModel } from "@ifaa-components/ui-components";

export class MemberJoinModel {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  token: string = '';
  password: string = '';
  dob: string = '';
  gender: number = 0;
  mobile: string = '';
  address: AddressModel = new AddressModel();
  mobileCode: string = '';
  tfn: string = '';
  tfnAgreement: boolean = false;
  readAllInformationAgreement: boolean = false;
  iUnderstandBalanceInvested: boolean = false;
  conscentAgreement: boolean = false;
  superFundList: SuperFundModel[] = [];
  emailValidatedToken: string = '';
  emailCode: string = '';
  investmentOption: number = 1;
}

export class SuperFundModel {
  providerId: number = 0;
  usi: string = '';
  nameOfSuperFund: string = '';
  memberNumber: string = '';
  amountToTransfer: number = 0;
  wholeAmount: string = '1';
}

export class SendMobileCodeResponseModel
{ 
    isMobileVerified:boolean;
    message :string;
}
