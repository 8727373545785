import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';

@Injectable()
export class MainEffects {
  constructor(private store: Store<any>,
    private actions$: Actions) {
  }

  // searchRequest$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.MainSearchRequestAction),
  //   switchMap((action: any) => this.service.get(action.search, action.page)),
  //   map((data: any) => {
  //     return ActionsList.MainSearchResponseAction({ payload: data.members });
  //   })
  // ));

}
