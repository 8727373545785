import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { ClearSearchAction, RequestAction, ResponseAction, SelectOptionAction, ResetAction, ClearSelectedSearchAction, SuperAnnuationIsLoadingAction } from './actions';
import { ISuperannuationProviderSearchState, SuperannuationProviderItemModel, SuperannuationProviderSearchModel } from './state';
import { Helper } from '@ifaa-components/ui-components'
export const formName = 'SuperannuationProviderSearchEditForm';

var helper = new Helper();
export const state: ISuperannuationProviderSearchState = {
  form: createFormGroupState(formName, new SuperannuationProviderSearchModel()),
  lastModifiedTime: null,
  isSearchLoading: false
};

export const validateSuperannuationProviderForm = updateGroup<SuperannuationProviderSearchModel>({
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      form: createFormGroupState(formName, new SuperannuationProviderSearchModel()),
      lastModifiedTime: null,
      isSearchLoading: false
    };
  }),
  on(RequestAction, (state, { data }) => {
    if (!data) {
      var clone = helper.clone(state.form.value);
      clone.list = [];
      return {
        ...state,
        isSearchLoading: false,
        form: setValue(clone)(state.form),
      };
    }
    else {
      return {
        ...state,
        isSearchLoading: true
      };
    }

  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.list = payload;
    return {
      ...state,
      isSearchLoading: false,
      form: setValue(clone)(state.form),
    };
  }),
  on(SuperAnnuationIsLoadingAction, (state, { payload }) => {
    return {
      ...state,
      isSearchLoading: payload,
    };
  }),
  on(SelectOptionAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.selected = payload;
    clone.q = '';
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(ClearSearchAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.q = '';
    clone.list = [];
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(ClearSelectedSearchAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.selected = new SuperannuationProviderItemModel();
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),

);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateSuperannuationProviderForm,
);

export function superannuationProviderSearchReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
