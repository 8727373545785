import { environment } from 'src/environments/environment';
import { IsLoadingAction, LogoutRequestAction } from './../store/common/common.actions';
import { Injectable, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from '../../../node_modules/rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { finalize } from "rxjs/internal/operators/finalize";
import { commomState_IsLoading } from '../store/common/common.selectors';
import { SetErrorPageDataAction } from '../views/member-join/actions';
import { SuperAnnuationIsLoadingAction } from '../components/superannuation-provider-search/actions';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  requestCount = 0;
  loadingSub: Subscription;
  isLoading$ = this.store.pipe(select(commomState_IsLoading));

  constructor(private store: Store<any>,

    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
    this.isLoading$.subscribe(x => {
      this.requestCount = 0;
    })
  }

  showError(errors) {
    const toastr = this.toastr;
    errors.forEach(function (element) {
      toastr.error(element);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.endsWith('.json') && request.url.startsWith(environment.apiUrl)) {
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
      }),

      finalize(() => {

      }),
      catchError((error, caught) => {

        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0);
        setTimeout(() => { this.store.dispatch(SuperAnnuationIsLoadingAction({ payload: false })); }, 0);

        if (error.status === 401) {
          window.localStorage.setItem('token_name', '');
          if (!this.router.url.toLowerCase().startsWith('/resetpassword')) {
            this.router.navigate(['/login']);
          }
        } else {
          if (error.error && error.error.message) {
            if (error.error.displayErrorPage == true) {
              setTimeout(() => { this.store.dispatch(SetErrorPageDataAction({ data: error.error })); }, 0);
            }
            else {
              this.showError([error.error.message]);
            }
          }
        }
        return throwError(error);
      }) as any);
  }
}
